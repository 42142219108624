/**
 * 功能：权限控制
 *
 * Demo: <input type="text" v-focus v-default />
 */
import type { Directive, DirectiveBinding } from 'vue';
import { AuthStore } from '@/stores/module/auth';
// TODO 获取按钮权限列表
const auth: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    const authStore = AuthStore();
    if (authStore.authButtons.indexOf(binding.value) === -1) {
      el.remove();
    }
  },
};

export default auth;
