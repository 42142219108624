<template>
  <div class="panel-item" @click="showMsg">
    <el-tooltip effect="dark" content="消息中心" placement="bottom">
      <el-badge :hidden="msgList.length == 0" :value="msgList.length" class="badge" type="danger">
        <el-icon><ChatDotRound /></el-icon>
      </el-badge>
    </el-tooltip>
    <el-drawer title="新消息" v-model="msg" :size="400" append-to-body destroy-on-close>
      <el-container>
        <el-main class="nopadding">
          <el-scrollbar>
            <ul class="msg-list">
              <li v-for="item in msgList" v-bind:key="item.id">
                <a :href="item.link" target="_blank">
                  <div class="msg-list__icon">
                    <el-badge is-dot type="danger">
                      <el-avatar :size="40" :src="item.avatar"></el-avatar>
                    </el-badge>
                  </div>
                  <div class="msg-list__main">
                    <h2>{{ item.title }}</h2>
                    <p>{{ item.describe }}</p>
                  </div>
                  <div class="msg-list__time">
                    <p>{{ item.time }}</p>
                  </div>
                </a>
              </li>
              <el-empty v-if="msgList.length == 0" description="暂无新消息" :image-size="100"></el-empty>
            </ul>
          </el-scrollbar>
        </el-main>
        <el-footer>
          <el-button type="primary">消息中心</el-button>
          <el-button @click="markRead">全部设为已读</el-button>
        </el-footer>
      </el-container>
    </el-drawer>
  </div>
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue';

const msgList = reactive([
  {
    id: 1,
    avatar: 'https://lolicode.gitee.io/scui-doc/demo/img/avatar.jpg',
    title: '222',
    describe: '这是一段描述',
    link: '#',
    time: '2022-09-27 18:12:10',
  },
  {
    id: 2,
    avatar: 'https://lolicode.gitee.io/scui-doc/demo/img/avatar2.gif',
    title: '222',
    describe: '这是一段描述',
    link: '#',
    time: '2022-09-27 18:12:10',
  },
  {
    id: 3,
    avatar: 'https://lolicode.gitee.io/scui-doc/demo/img/logo.png',
    title: '222',
    describe: '这是一段描述',
    link: '#',
    time: '2022-09-27 18:12:10',
  },
]);
const msg = ref(false);
//显示短消息
const showMsg = () => {
  console.log('showmsg');
  msg.value = true;
};

//消息标记已读
const markRead = () => {
  console.log('markread');
};
</script>

<style scoped lang="scss">
.badge {
  width: 16px;
  height: 16px;
}
.el-drawer__header {
  margin: 0px;
}
.el-drawer__body {
  margin: 0px;
}
.msg-list {
  li {
    border-top: 1px solid #eee;

    a {
      display: flex;
      padding: 20px;
    }

    a:hover {
      background: #ecf5ff;
    }
  }

  &__icon {
    width: 40px;
    margin-right: 15px;
  }

  &__main {
    flex: 1;

    h2 {
      font-size: 15px;
      font-weight: normal;
      color: #333;
    }

    p {
      font-size: 12px;
      color: #999;
      line-height: 1.8;
      margin-top: 5px;
    }
  }

  &__time {
    width: 100px;
    text-align: right;
    color: #999;
  }
}
</style>
