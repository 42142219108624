<template>
  <!-- 用户信息区域 -->
  <el-card class="box-card" shadow="hover">
    <div class="flex items-center">
      <div style="width: 68px !important; height: 68px">
        <el-avatar :size="68" :src="userInfo.avatar" />
      </div>
      <el-row style="margin-left: 16px">
        <el-col :span="24">
          <div class="text-xl font-semibold">{{ userInfo.tip }}</div>
        </el-col>
        <el-col :span="24">
          <small class="info"
            >{{ userInfo.dept_text }} /
            <template v-for="(item, index) in userInfo.post_text" :key="index">
              {{ item.post_name }}
            </template>
          </small>
        </el-col>
        <el-col :span="24">
          <small class="info">上次登录IP: {{ userInfo.login_ip }} 上次登录时间: {{ userInfo.login_date }}</small>
        </el-col>
      </el-row>
    </div>
  </el-card>
  <!-- 快捷操作栏 -->
  <el-row :gutter="16">
    <el-col :span="3" v-for="item in quickTools" :key="item.name" class="text item"
      ><el-card shadow="hover" class="box-card tools">
        <House style="width: 30px"></House>
        <div>
          {{ item.name }}
        </div>
      </el-card></el-col
    >
  </el-row>
  <!-- 通知 知识 消息 -->
  <el-row :gutter="16">
    <el-col :span="24" class="text item">
      <el-card class="box-card" shadow="hover">
        <el-tabs v-model="activeName" @tab-change="handleClick">
          <el-tab-pane label="企业公告" name="1">
            <el-table :data="tableData" style="width: 100%" border>
              <el-table-column class-name="headerTable" prop="category_name" label="公告分类" width="150"> </el-table-column>
              <el-table-column class-name="headerTable" prop="title" label="公告标题">
                <template #default="scope">
                  <span style="color: var(--el-color-primary); cursor: pointer" @click="detailClick(scope.row.notice_id)">
                    {{ scope.row.title }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column class-name="headerTable" prop="create_time" label="发布时间" width="200"> </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="知识列表" name="2">
            <el-table :data="tableData" style="width: 100%" border>
              <el-table-column class-name="headerTable" prop="category_name" label="公告分类" width="150"> </el-table-column>
              <el-table-column class-name="headerTable" prop="title" label="公告标题">
                <template #default="scope">
                  <span style="color: var(--el-color-primary); cursor: pointer" @click="detailClick(scope.row.notice_id)">
                    {{ scope.row.title }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column class-name="headerTable" prop="create_time" label="发布时间" width="200"> </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </el-col>
  </el-row>
  <!-- detail -->
  <ProDrawer v-model="detailVisible" size="50%" title="">
    <template #body>
      <header class="text-3xl">{{ detailData.data.title }}</header>
      <div class="pt-5 w-full pb-5" style="border-bottom: #c8c9cc 1px solid">
        <small style="color: #73767a" class="text-base mr-3">发表于:{{ detailData.data.create_time }}</small>
        <small style="color: #73767a" class="text-base mr-3">发表用户:{{ detailData.data.create_user }}</small>
        <small style="color: #73767a" class="text-base mr-3">修改于:{{ detailData.data.update_time }}</small>
        <small style="color: #73767a" class="text-base mr-3">分类:{{ detailData.data.category_name }}</small>
      </div>
      <div class="pb-5 pt-5" style="font-size: 22px" v-html="detailData.data.content"></div>
      <div class="text-xl">{{ detailData.data.remark }}</div>
    </template>
  </ProDrawer>
</template>

<script setup lang="ts">
import { reactive, computed, onMounted, ref } from 'vue';
import { useUserStore } from '@/stores/user';
import { getList, getOne } from '@/api/system/notice';
const detailVisible = ref(false);
const detailData = reactive<any>({
  data: {
    title: '',
    content: '',
    remark: '',
    category_name: '',
    update_time: '',
    create_time: '',
    create_user: '',
  },
});
const quickTools = reactive([
  {
    name: '用户',
    icon: '',
  },
  {
    name: '分析',
    icon: '',
  },
  {
    name: '商品',
    icon: '',
  },
  {
    name: '订单',
    icon: '',
  },
  {
    name: '票据',
    icon: '',
  },
  {
    name: '消息',
    icon: '',
  },
  {
    name: '价签',
    icon: '',
  },
  {
    name: '配置',
    icon: '',
  },
]);
const tableData = reactive<any>([]);
const userStore = useUserStore();
const userInfo = computed(() => {
  return userStore.userInfo;
});

const getData = (id: number) => {
  getList({ type: id }).run({
    manual: false,
    onSuccess: (res) => {
      tableData.length = 0;
      tableData.push(...res.data.list);
    },
  });
};

const activeName = ref('1');

const handleClick = (tab: number) => {
  getData(tab);
};

onMounted(() => {
  getData(1);
});

const detailClick = (id: number) => {
  getOne({ notice_id: id }).run({
    manual: false,
    onSuccess: (res) => {
      detailVisible.value = true;
      detailData.data = res.data;
    },
  });
};
</script>

<style scoped lang="scss">
.box-card {
  margin-bottom: 16px;
}
.tools {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.info {
  font-size: 15px;
  color: #73767a;
}
:deep(.el-tabs__item) {
  font-size: 20px;
}
:deep(.el-table th.el-table__cell) {
  background: var(--el-fill-color-light) !important;
}
:deep(.el-drawer__header) {
  display: none;
}
</style>
