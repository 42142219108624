<!--
经典布局
-->
<template xmlns:icons-svg--typcn="">
  <el-header height="56px" style="background-color: #222b45; color: #fff" class="admin-topbar">
    <Topbar>
      <template #breadcrumb>
        <div style="background-color: #222b45; margin-left: -20px" :style="{ width: menuIsCollapse ? '64px' : '200px' }">
          <div class="admin-side-split-top">
            <router-link to="/">
              <img class="logo" title="logo" src="../../assets/logo.png" />
            </router-link>
          </div>
        </div>
        <div class="admin-side-bottom mr-3" style="background-color: #222b45; color: #fff; border: none; height: 40px" @click="onMenuCollapse">
          <el-icon>
            <Expand v-if="menuIsCollapse" />
            <Fold v-else />
          </el-icon>
        </div>
      </template>
    </Topbar>
  </el-header>
  <el-container class="app-wrapper">
    <el-scrollbar height="100%">
      <el-menu
        router
        text-color="#0b0b0b"
        class="el-menu-vertical-demo"
        :collapse="menuIsCollapse"
        :default-active="route.path"
        :collapse-transition="false"
      >
        <template v-for="(item, index) in menu" :key="item.id">
          <!--   有子项  无点击事件 继续渲染子项   -->
          <el-sub-menu :index="index" v-if="item.children">
            <template #title>
              <icons-font style="width: 25px; height: 18px" :name="item.meta.icon" type="svg"></icons-font>
              <span v-if="!menuIsCollapse">{{ item.meta.title }}</span>
            </template>
            <template v-for="e in item.children" :key="e.id">
              <!--   二层有子项 无点击事件 继续渲染子项  -->
              <el-sub-menu :index="e.path" v-if="e.children">
                <template #title>
                  <icons-font style="width: 25px; height: 18px" :name="e.children[0].meta.icon" type="svg"></icons-font>
                  <span>{{ e.children[0].name }}</span>
                </template>
                <template v-for="a in e.children" :key="e.id">
                  <!--   三层无子项 三层showMenu需要传递前两层父级和本级   -->
                  <el-menu-item :index="a.path" @click="showMenu(item, e, a)">
                    <template #title>
                      <icons-font style="width: 25px; height: 18px" :name="a.meta.icon" type="svg"></icons-font>
                      <span>{{ a.meta.title }}</span>
                    </template>
                  </el-menu-item>
                </template>
              </el-sub-menu>
              <!--   二层无子项 二层showMenu需要传递父级和本级 -->
              <el-menu-item :index="e.path" v-else @click="showMenu(item, e)">
                <template #title>
                  <icons-font style="width: 25px; height: 18px" :name="e.meta.icon" type="svg"></icons-font>
                  <span>{{ e.meta.title }}</span>
                </template>
              </el-menu-item>
            </template>
          </el-sub-menu>
          <!--   无子项  showMenu只传递本级 -->
          <el-menu-item :index="index" v-else @click="showMenu(item)">
            <template #title>
              <icons-font style="width: 25px; height: 18px" :name="item.meta.icon" type="svg"></icons-font>
              <span v-if="!menuIsCollapse">{{ item.meta.title }}</span>
            </template>
          </el-menu-item>
        </template>
      </el-menu>
    </el-scrollbar>
    <el-container class="admin-body">
      <Tabs v-if="globalStore.themeConfig.tabs"></Tabs>
      <el-main class="admin-main" id="admin-main">
        <RouterView v-slot="{ Component, route }">
          <Transition name="slide-fade">
            <keep-alive>
              <component :is="Component" :key="route.fullPath" />
            </keep-alive>
          </Transition>
        </RouterView>
        <!--        <iframe-view></iframe-view>-->
      </el-main>
      <Footer v-if="globalStore.themeConfig.footer"></Footer>
    </el-container>
  </el-container>
</template>

<script setup lang="ts">
import { reactive, ref, nextTick, watch } from 'vue';
import { Expand, Fold } from '@element-plus/icons-vue';
import Topbar from '../components/topBar/index.vue';
import Tabs from '../components/tabs/index.vue';
import Footer from '../components/footer/index.vue';
import { menuStore } from '@/stores/module/menu';
import { GlobalStore } from '@/stores';
import { useRoute, useRouter } from 'vue-router';
import { TabsStore } from '@/stores/module/tabs';
const route = useRoute();
const globalStore = GlobalStore();
const router = useRouter();
//TODO 解构获得类型
const useMenuStore = menuStore();
const tabStore = TabsStore();
//折叠菜单
const menuIsCollapse = ref(false);
const onMenuCollapse = () => {
  menuIsCollapse.value = !menuIsCollapse.value;
};
// 导航栏选中样式
let activeShow = ref();

let nextMenu = reactive<Array<any>>([]);

const menu = reactive<any>([
  {
    path: '/',
    meta: {
      icon: 'icon-biaodanzujian-biaoge',
      title: '组件',
    },
    children: [
      {
        path: '/component/tableSelect',
        name: 'component-tableSelect',
        meta: { title: '表格选择器' },
      },
      // {
      //   path: '/component/remoteSelect',
      //   meta: { title: '远程选择器' },
      // },
      // {
      //   path: '/component/querySelect',
      //   name: 'component-querySelect',
      //   meta: { title: '查询选择器' },
      // },
      {
        path: '/component/template',
        name: 'component-template',
        meta: { title: '表格模板' },
      },
      {
        path: '/component/badge',
        name: 'component-badge',
        meta: { title: '状态徽标' },
      },
      {
        path: '/component/proDrawer',
        name: 'component-proDrawer',
        meta: { title: '抽屉弹出框' },
      },
      {
        path: '/component/fileSelect',
        meta: { title: '资源选择器' },
      },
      {
        path: '/component/map',
        meta: { title: '位置选择器' },
      },
      {
        path: '/component/proTable',
        meta: { title: '表格' },
      },
      {
        path: '/component/icon',
        meta: { title: 'icones' },
      },
      {
        path: '/component/WindiCss',
        meta: { title: 'WindiCss' },
      },
    ],
  },
]);

const title = ref('');
// 处理 一层无子项
const firstMenu = (first: any) => {
  useMenuStore.setBreadList([first.name]);
  title.value = first.name;
};
// 处理 二层无子项
const secondMenu = (first: any, second: any) => {
  useMenuStore.setBreadList([first.name, second.name]);
  title.value = second.name;
};
// 处理 三层无子项
const thirdMenu = (first: any, second: any, third: any) => {
  useMenuStore.setBreadList([first.name, second.name, third.name]);
  title.value = third.name;
};

/**
 * @showMenu 判断有无子项 获取 tabs 和 格式化面包屑导航为一维数组
 * @param first 最外层菜单  对应 el-menu menu
 * @param second 第二层菜单 对应 el-menu e  非必传
 * @param third 第三层菜单  对应 el-menu a  非必传
 * @return void
 * */
const showMenu = (first: any, second?: any, third?: any) => {
  if (first && !second && !third) {
    firstMenu(first);
  }
  if (first && second && !third) {
    secondMenu(first, second);
  }
  if (first && second && third) {
    thirdMenu(first, second, third);
  }
};

nextTick(() => {
  menu.unshift(...useMenuStore.menuList);
});

// 监听路由的变化（防止浏览器后退/前进不变化 tabsMenuValue）
watch(
  () => route.path,
  (value) => {
    setTimeout(() => {
      let params = {
        title: title.value,
        breadList: [],
        path: route.path,
        close: true,
      };
      params.breadList.push(...useMenuStore.breadList);
      tabStore.addTabs(params);
    }, 100);
    tabStore.tabsMenuList.forEach((item) => {
      if (item.path === value) {
        useMenuStore.setBreadList(item.breadList);
      }
    });
  },
  {
    immediate: true,
  },
);

// 刷新当前页面
const isRouterRefresh = ref(true);
const refreshCurrentPage = () => {
  isRouterRefresh.value = false;
  nextTick(function () {
    isRouterRefresh.value = true;
  });
};
provide('refresh', refreshCurrentPage);
</script>

<style lang="scss">
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.el-menu {
  border: 0px;
}
.icon {
  width: 10px;
  height: 1px;
  //vertical-align: -0.15em;
  ////fill: currentColor;
  //overflow: hidden;
}
.slide-fade-enter-active {
  animation: enter 0.9s;
}

.slide-fade-leave-active {
  animation: leave 0.4s;
}

@keyframes enter {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  50% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes leave {
  50% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(100px);
    opacity: 0;
  }
}
</style>
