<template>
  <div class="left-panel">
    <slot name="breadcrumb"></slot>
    <el-breadcrumb separator-icon="el-icon-arrow-right" class="hidden-sm-and-down">
      <transition-group name="breadcrumb" mode="out-in">
        <el-breadcrumb :separator-icon="ArrowRight" v-if="globalStore.themeConfig.breadcrumb">
          <el-breadcrumb-item v-for="item in useMenuStore.breadList" :key="item"
            ><span :style="{ color: themeConfig.layout === 'classic' ? '#fff' : 'var(--el-text-color-regular)' }">{{
              item
            }}</span></el-breadcrumb-item
          >
          <!--    <el-icon class="icon"  v-if="true"><component :is="item.meta.icon" /></el-icon>      -->
        </el-breadcrumb>
      </transition-group>
    </el-breadcrumb>
  </div>
  <div class="center-panel"></div>
  <div class="right-panel">
    <slot></slot>
    <LockScreen></LockScreen>
    <Size></Size>
    <Setting></Setting>
    <Search></Search>
    <fullscreen></fullscreen>
    <Task></Task>
    <Message></Message>
    <Personal></Personal>
  </div>
</template>

<script setup lang="ts">
import { menuStore } from '@/stores/module/menu';
import { ArrowRight } from '@element-plus/icons-vue';
import Search from './search.vue';
import Message from './message.vue';
import Personal from './personal.vue';
import Setting from './setting.vue';
import Task from '../task/index.vue';
import Size from './size.vue';
import LockScreen from './lockscreen.vue';
import fullscreen from './fullscreen.vue';
import { GlobalStore } from '@/stores';
import { computed } from 'vue';
const globalStore = GlobalStore();
//TODO 解构获得类型
const useMenuStore = menuStore();
const themeConfig = computed(() => globalStore.themeConfig);
</script>
