<template>
  <div class="panel-item" @click="search">
    <el-tooltip effect="dark" content="搜索" placement="bottom">
      <el-icon>
        <Search />
      </el-icon>
    </el-tooltip>
  </div>

  <el-dialog v-model="searchVisible" :width="700" title="搜索" custom-class="drawerBG" center destroy-on-close>
    <search @success="searchVisible = false"></search>
  </el-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue';

let searchVisible = ref(false);

//搜索
const search = () => {
  searchVisible.value = true;
};
</script>

<style scoped lang="scss"></style>
