<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'layout',
})
</script>
<!-- 这里是一次性加载 LayoutComponents -->
<template>
  <component :is="LayoutComponents[themeConfig.layout]" />
</template>

<script setup lang="ts" name="layout">
import { ref, computed, onMounted, markRaw } from 'vue';
// import { getAuthButtons, getAuthMenu } from '@/api/modules/login';
import { userAuth } from '@/api/module/common';
import { handleRouter } from '@/utils/util';
import { GlobalStore } from '@/stores';

import { AuthStore } from '@/stores/module/auth';
import LayoutVertical from './LayoutVertical/index.vue';
import LayoutClassic from './LayoutClassic/index.vue';
import LayoutTransverse from './LayoutTransverse/index.vue';
import LayoutColumns from './LayoutColumns/index.vue';
import { menuStore } from '@/stores/module/menu';
// const useMenuStore = menuStore();

const LayoutComponents: any = {
  // classic: LayoutClassic, 经典
  classic: markRaw(LayoutClassic),
  // columns: LayoutColumns,
  columns: markRaw(LayoutColumns),
  // vertical: LayoutVertical,
  vertical: markRaw(LayoutVertical),
  // transverse: LayoutTransverse,
  transverse: markRaw(LayoutTransverse),
};

const MenuStore = menuStore();
const authStore = AuthStore();
const globalStore = GlobalStore();
const themeConfig = computed(() => globalStore.themeConfig);
const isCollapse = computed((): boolean => MenuStore.isCollapse);

onMounted(() => {
  console.log('globalStore', globalStore.themeConfig);
  // getAuthButtonsList();
  getMenuList();
});

// 获取按钮权限列表
// const getAuthButtonsList = async () => {
//   const { data } = await getAuthButtons();
//   data && authStore.setAuthButtons(data);
// };

// 获取菜单列表中
const getMenuList = () => {
  userAuth().run({
    manual: false,
    onSuccess: (res: any) => {
      MenuStore.setMenuList(res.data.menu);
      // 把路由菜单处理成一维数组（存储到 pinia ）
      authStore.setAuthRouter(handleRouter(res.data.menu));
      authStore.setAuthButtons(res.data.btn_auth);
      // data && menuStore.setMenuList(data);
    },
  });
};

// 监听窗口大小变化，折叠 aside
const screenWidth = ref<number>(0);
const listeningWindow = () => {
  window.onresize = () => {
    return (() => {
      screenWidth.value = document.body.clientWidth;
      if (isCollapse.value === false && screenWidth.value < 1200) MenuStore.setCollapse();
      if (isCollapse.value === true && screenWidth.value > 1200) MenuStore.setCollapse();
    })();
  };
};
listeningWindow();
//TODO 检测系统是否需要升级
</script>
