{
  "errMsg400": "請求錯誤",
  "errMsg401": "未授權，請重新登錄",
  "errMsg403": "拒絕訪問",
  "errMsg404": "請求出錯",
  "errMsg405": "請求方法未允許",
  "errMsg408": "請求超時",
  "errMsg500": "服務器錯誤",
  "errMsg501": "服務未實現",
  "errMsg502": "網絡錯誤",
  "errMsg503": "服務不可用",
  "errMsg504": "網絡超時",
  "errMsg505": "Http版本不支持該請求",
  "errMsgTip": "請稍後重試!",
  "timeout": "請求超時"
}
