<template>
  <div class="panel-item" @click="toggle">
    <el-tooltip effect="dark" content="是否全屏" placement="bottom">
      <icons-svg-fullscreen-exit v-if="isFullscreen" />
      <icons-svg-fullscreen v-else />
    </el-tooltip>
  </div>
</template>

<script setup lang="ts">
//全屏
import { useFullscreen } from '@vueuse/core';

const { toggle, isFullscreen } = useFullscreen();
</script>

<style scoped lang="scss"></style>
