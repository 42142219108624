<template>
  <div class="panel-item" @click="toVue">
    <icons-svg-uiw:file-type-vue style="font-size: 24px; color: #07c160; cursor: pointer" />
  </div>
  <div class="panel-item" @click="openDrawer">
    <el-tooltip effect="dark" content="布局设置" placement="bottom">
      <el-icon>
        <Setting />
      </el-icon>
    </el-tooltip>
    <el-drawer v-model="drawerVisible" title="布局设置" size="300px">
      <!-- 布局切换 -->
      <el-divider class="divider" content-position="center">
        <el-icon><Notification /></el-icon>
        布局切换
      </el-divider>
      <div class="layout-box">
        <el-tooltip effect="dark" content="分栏" placement="top" :show-after="200">
          <div :class="['layout-item layout-columns', layout === 'columns' ? 'is-active' : '']" @click="changeLayout('columns')">
            <div class="layout-dark"></div>
            <div class="layout-light"></div>
            <div class="layout-content"></div>
            <el-icon v-if="layout == 'columns'"><CircleCheckFilled /></el-icon>
          </div>
        </el-tooltip>
        <el-tooltip effect="dark" content="经典" placement="top" :show-after="200">
          <div :class="['layout-item layout-classic', layout == 'classic' ? 'is-active' : '']" @click="changeLayout('classic')">
            <div class="layout-dark"></div>
            <div class="layout-container">
              <div class="layout-light"></div>
              <div class="layout-content"></div>
            </div>
            <el-icon v-if="layout == 'classic'"><CircleCheckFilled /></el-icon>
          </div>
        </el-tooltip>
        <el-tooltip effect="dark" content="混合" placement="top" :show-after="200">
          <div :class="['layout-item layout-vertical', layout == 'vertical' ? 'is-active' : '']" @click="changeLayout('vertical')">
            <div class="layout-dark"></div>
            <div class="layout-container">
              <div class="layout-light"></div>
              <div class="layout-content"></div>
            </div>
            <el-icon v-if="layout == 'vertical'"><CircleCheckFilled /></el-icon>
          </div>
        </el-tooltip>
        <el-tooltip effect="dark" content="横向" placement="top" :show-after="200">
          <div :class="['layout-item layout-transverse', layout == 'transverse' ? 'is-active' : '']" @click="changeLayout('transverse')">
            <div class="layout-dark"></div>
            <div class="layout-content"></div>
            <el-icon v-if="layout == 'transverse'"><CircleCheckFilled /></el-icon>
          </div>
        </el-tooltip>
      </div>
      <el-divider class="flex justify-center" content-position="center">
        <el-icon><ColdDrink /></el-icon>
        全局主题
      </el-divider>

      <div class="flex justify-between items-center mb-1">
        <span class="textColor font">主题颜色</span>
        <el-color-picker v-model="themeConfig.primary" :predefine="colorList" @change="changePrimary"> </el-color-picker>
      </div>
      <div class="flex justify-between items-center mb-1">
        <span class="textColor font">暗黑模式</span>
        <el-switch
          v-model="themeConfig.isDark"
          @change="onAddDarkChange"
          inline-prompt
          active-color="#0a0a0a"
          inactive-color="#dcdfe6"
          :active-icon="Sunny"
          :inactive-icon="Moon"
        />
      </div>
      <div class="flex justify-between items-center mb-1">
        <span class="textColor font">灰色模式</span>
        <el-switch v-model="themeConfig.isGrey" @change="changeGreyOrWeak($event, 'grey')" />
      </div>
      <div class="flex justify-between items-center mb-1">
        <span class="textColor font">色弱模式</span>
        <el-switch v-model="themeConfig.isWeak" @change="changeGreyOrWeak($event, 'weak')" />
      </div>
      <br />
      <el-divider class="divider" content-position="center">
        <el-icon><Setting /></el-icon>
        界面设置
      </el-divider>
      <div class="flex justify-between items-center mb-1">
        <span class="textColor font">面包屑导航</span>
        <el-switch v-model="themeConfig.breadcrumb" />
      </div>
      <div class="flex justify-between items-center mb-1">
        <span class="textColor font">标签栏</span>
        <el-switch v-model="themeConfig.tabs" />
      </div>
      <div class="flex justify-between items-center mb-1">
        <span class="textColor font">页脚</span>
        <el-switch v-model="themeConfig.footer" />
      </div>
      <template #footer>
        <div style="flex: auto">
          <el-button @click="drawerVisible = false">关闭</el-button>
          <el-button type="primary" @click="confirmClick">保存</el-button>
        </div>
      </template>
    </el-drawer>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useTheme } from '@/hooks/useTheme';
import { menuStore } from '@/stores/module/menu';
import { GlobalStore } from '@/stores';
import { DEFAULT_PRIMARY } from '@/config/server';

import { Sunny, Moon, Setting } from '@element-plus/icons-vue';

// 预定义主题颜色
const colorList = [DEFAULT_PRIMARY, '#DAA96E', '#0C819F', '#409EFF', '#27ae60', '#ff5c93', '#e74c3c', '#fd726d', '#f39c12', '#9b59b6'];

// 主题初始化
const globalStore = GlobalStore();
const themeConfig = computed(() => globalStore.themeConfig);

const MenuStore = menuStore();
const isCollapse = computed({
  get() {
    return MenuStore.isCollapse;
  },
  set() {
    MenuStore.setCollapse();
  },
});

const { changePrimary, changeGreyOrWeak, switchDark } = useTheme();

const onAddDarkChange = () => {
  switchDark();
};

// 打开主题设置
const drawerVisible = ref(false);
const openDrawer = () => {
  drawerVisible.value = true;
};
const layout = ref('');
layout.value = globalStore.themeConfig.layout;
const confirmClick = () => {
  globalStore.setThemeConfig({ ...themeConfig.value, layout: layout.value });
  globalStore.setting();
};

// 切换布局方式
const changeLayout = (val: string) => {
  layout.value = val;
  console.log(val);
};
const toVue = () => {
  window.open('https://vue3js.cn/');
};
</script>

<style scoped lang="scss">
.textColor {
  color: #0d0d0d;
}
.layout-box {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 12px 0 0;
  .layout-item {
    position: relative;
    box-sizing: border-box;
    width: 95px;
    height: 67px;
    padding: 6px;
    margin-bottom: 20px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 0 5px 1px var(--el-border-color-lighter);
    transition: all 0.2s;
    .layout-dark {
      background-color: var(--el-color-primary);
      border-radius: 3px;
    }
    .layout-light {
      background-color: var(--el-color-primary-light-5);
      border-radius: 3px;
    }
    .layout-content {
      background-color: var(--el-color-primary-light-8);
      border: 1px dashed var(--el-color-primary);
      border-radius: 3px;
    }
    .el-icon {
      position: absolute;
      right: 10px;
      bottom: 10px;
      color: var(--el-color-primary);
      transition: all 0.2s;
    }
    &:hover {
      box-shadow: 0 0 5px 1px var(--el-border-color-darker);
    }
  }
  .is-active {
    box-shadow: 0 0 0 2px var(--el-color-primary) !important;
  }
  .layout-vertical {
    display: flex;
    justify-content: space-between;
    .layout-dark {
      width: 20%;
    }
    .layout-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 72%;
      .layout-light {
        height: 20%;
      }
      .layout-content {
        height: 67%;
      }
    }
  }
  .layout-classic {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .layout-dark {
      height: 22%;
    }
    .layout-container {
      display: flex;
      justify-content: space-between;
      height: 70%;
      .layout-light {
        width: 20%;
      }
      .layout-content {
        width: 70%;
      }
    }
  }
  .layout-transverse {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .layout-dark {
      height: 20%;
    }
    .layout-content {
      height: 67%;
    }
  }
  .layout-columns {
    display: flex;
    justify-content: space-between;
    .layout-dark {
      width: 14%;
    }
    .layout-light {
      width: 17%;
    }
    .layout-content {
      width: 55%;
    }
  }
}

.menu-mode {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  .mode {
    position: relative;
    width: 80px;
    height: 55px;
    margin: 10px;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    background-color: var(--g-app-bg);
    box-shadow: 0 0 5px 1px var(--el-border-color-lighter);
    transition: 0.2s;
    &:hover {
      box-shadow: 0 0 5px 1px var(--el-border-color-darker);
    }
    &.active {
      box-shadow: 0 0 0 2px var(--el-color-primary);
    }
    &::before,
    &::after,
    .mode-container {
      pointer-events: none;
      position: absolute;
      border-radius: 3px;
    }
    .mode-container::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: var(--g-sub-sidebar-menu-active-bg);
      opacity: 0.2;
    }
    &-side {
      &::before {
        content: '';
        top: 5px;
        left: 5px;
        bottom: 5px;
        width: 10px;
        background-color: var(--g-sub-sidebar-menu-active-bg);
      }
      &::after {
        content: '';
        top: 5px;
        left: 20px;
        bottom: 5px;
        width: 15px;
        background-color: var(--g-sub-sidebar-menu-active-bg);
        opacity: 0.5;
      }
      .mode-container {
        top: 5px;
        left: 40px;
        right: 5px;
        bottom: 5px;
        border: 1px dashed var(--g-sub-sidebar-menu-active-bg);
      }
    }
    &-head {
      &::before {
        content: '';
        top: 5px;
        left: 5px;
        right: 5px;
        height: 10px;
        background-color: var(--g-sub-sidebar-menu-active-bg);
      }
      &::after {
        content: '';
        top: 20px;
        left: 5px;
        bottom: 5px;
        width: 15px;
        background-color: var(--g-sub-sidebar-menu-active-bg);
        opacity: 0.5;
      }
      .mode-container {
        top: 20px;
        left: 25px;
        right: 5px;
        bottom: 5px;
        border: 1px dashed var(--g-sub-sidebar-menu-active-bg);
      }
    }
    &-single {
      &::before {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        bottom: 5px;
        width: 15px;
        background-color: var(--g-sub-sidebar-menu-active-bg);
        opacity: 0.5;
      }
      .mode-container {
        top: 5px;
        left: 25px;
        right: 5px;
        bottom: 5px;
        border: 1px dashed var(--g-sub-sidebar-menu-active-bg);
      }
    }
    i {
      position: absolute;
      right: 10px;
      bottom: 10px;
      display: none;
    }
    &.active i {
      display: block;
      color: var(--el-color-primary);
    }
  }
}
</style>
