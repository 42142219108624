<template>
  <div class="panel-item" @click="hanldLock">
    <el-tooltip effect="dark" content="锁屏" placement="bottom">
      <el-icon><Lock /></el-icon>
    </el-tooltip>
  </div>
</template>

<script setup lang="ts">
//全屏
const hanldLock = () => {};
</script>

<style scoped lang="scss"></style>
