<template>
  <el-card class="box-card" style="background: var(--el-color-primary)" shadow="hover">
    <div id="time">
      <div class="date flex flex-col">
        <span class="text-3xl font-bold">{{ time }}</span>
        <span class="text-base font-normal">
          {{ date }}
        </span>
      </div>
      <div class="weather flex flex-col">
        <span class="text-3xl font-semibold">{{ weather.data.city }} {{ weather.data.weather }} {{ weather.data.temperature }}℃</span>
        <span class="text-base font-normal">
          湿度 {{ weather.data.humidity }} {{ weather.data.winddirection }}风 {{ weather.data.windpower }}级
        </span>
      </div>
    </div>
  </el-card>
  <el-calendar v-model="value" />
  <el-card class="box-card" style="background: var(--el-color-primary)" shadow="never">
    日程
    <br />
    添加日程
  </el-card>
  <el-card class="box-card" shadow="never"> 日程 </el-card>
</template>

<script setup lang="ts">
import { ref, onMounted, onDeactivated, onActivated } from 'vue';
import axios from 'axios';
const date = ref<string>('');
const time = ref<string>('');
const weather = reactive({
  data: {
    city: '10010',
    weather: '',
    temperature: '',
    humidity: '',
    winddirection: '',
    windpower: '',
  },
});

const getTime = (type: string) => {
  let year = new Date().getFullYear(); //获取当前时间的年份
  let month = new Date().getMonth() + 1; //获取当前时间的月份
  let day = new Date().getDate(); //获取当前时间的天数
  let hours: string | number = new Date().getHours(); //获取当前时间的小时
  let minutes: string | number = new Date().getMinutes(); //获取当前时间的分数
  let seconds: string | number = new Date().getSeconds(); //获取当前时间的秒数
  //当小于 10 的是时候，在前面加 0
  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  if (type === 'hours') {
    return hours + ':' + minutes + ':' + seconds;
  } else {
    return year + '年' + month + '月' + day + '日';
  }
};
let timeINterval: string | number | NodeJS.Timer | undefined;
// 进入开启定时器改变时间
onActivated(() => {
  timeINterval = setInterval(() => {
    date.value = getTime('date');
    time.value = getTime('hours');
  }, 1000);
});

onMounted(() => {
  axios.get('https://restapi.amap.com/v3/ip?key=48c340b3a587bcf9a2ecfd160c1420c1').then((res: any) => {
    axios
      .get(`https://restapi.amap.com/v3/weather/weatherInfo?key=48c340b3a587bcf9a2ecfd160c1420c1&city=${res.data.adcode}&extensions=base`)
      .then((res) => {
        weather.data = res.data.lives[0];
      });
  });
});

// 退出关闭定时器
onDeactivated(() => {
  clearInterval(timeINterval);
});
</script>

<style scoped lang="scss">
:deep(.el-calendar-table .el-calendar-day) {
  height: 75px !important;
}
.box-card {
  margin-bottom: 16px;
  color: var(--el-color-white);
}
#time {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.weather {
  text-align: right;
}
</style>
