{
  "timeout": "请求超时",
  "errMsgTip": "请稍后重试!",
  "errMsg400": "请求错误",
  "errMsg401": "未授权，请重新登录",
  "errMsg403": "拒绝访问",
  "errMsg404": "请求出错",
  "errMsg405": "请求方法未允许",
  "errMsg408": "请求超时",
  "errMsg500": "服务器错误",
  "errMsg501": "服务未实现",
  "errMsg502": "网络错误",
  "errMsg503": "服务不可用",
  "errMsg504": "网络超时",
  "errMsg505": "Http版本不支持该请求"
}
