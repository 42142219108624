<!--
分栏布局
-->
<template xmlns:icons-svg--typcn="">
  <el-container class="app-wrapper">
    <el-aside width="64px" class="admin-side-split">
      <div class="admin-side-split-top">
        <router-link to="/">
          <img class="logo" title="logo" src="../../assets/logo.png" />
        </router-link>
      </div>
      <div class="admin-side-split-scrol">
        <el-scrollbar height="100%">
          <ul>
            <li v-for="(item, index) in menu" :key="index" @click="showMenu(index, item)" :class="{ active: index === activeShow }" v-waves>
              <icons-font style="width: 18px; height: 18px" :name="item.meta.icon" type="svg"></icons-font>
              <p>{{ item.meta.title }}</p>
            </li>
          </ul>
        </el-scrollbar>
      </div>
    </el-aside>
    <el-aside width="200px" v-if="nextMenu.length !== 0" :class="menuIsCollapse ? 'admin-side isCollapse' : 'admin-side'">
      <div v-show="!menuIsCollapse" class="admin-side-top">
        <h2>{{ menu[activeShow].remark }}</h2>
      </div>
      <div class="admin-side-scroll">
        <el-scrollbar height="100%">
          <el-menu router :default-active="route.path" :collapse="menuIsCollapse" :collapse-transition="false">
            <template v-for="item in nextMenu" :key="item.id">
              <el-sub-menu :index="item.path" v-if="item.children">
                <template #title>
                  <icons-font style="width: 25px; height: 18px" :name="item.meta.icon" type="svg"></icons-font>
                  <span v-show="!menuIsCollapse">{{ item.meta.title }}</span>
                </template>
                <el-menu-item-group v-for="(e, index) in item.children" :key="e.path" :index="e.id" @click="addDread([item], index)">
                  <el-menu-item :index="e.path">
                    <icons-font style="width: 18px; height: 18px" :name="e.meta.icon" type="svg"></icons-font>
                    <template #title> {{ e.meta.title }} </template>
                  </el-menu-item>
                </el-menu-item-group>
              </el-sub-menu>
              <el-menu-item v-else :index="item.path" @click="addDread([item])">
                <icons-font style="width: 25px; height: 18px" :name="item.meta.icon" type="svg"></icons-font>
                <template #title> {{ item.meta.title }} </template>
              </el-menu-item>
            </template>
          </el-menu>
        </el-scrollbar>
      </div>
      <div class="admin-side-bottom" @click="onMenuCollapse">
        <el-icon>
          <Expand v-if="menuIsCollapse" />
          <Fold v-else />
        </el-icon>
      </div>
    </el-aside>
    <el-container class="admin-body">
      <el-header height="56px" class="admin-topbar">
        <Topbar> </Topbar>
      </el-header>
      <Tabs v-if="globalStore.themeConfig.tabs"></Tabs>
      <el-main class="admin-main" id="admin-main">
        <RouterView v-slot="{ Component, route }">
          <Transition name="slide-fade">
            <keep-alive>
              <component :is="Component" :key="route.fullPath" />
            </keep-alive>
          </Transition>
        </RouterView>
        <!--        <iframe-view></iframe-view>-->
      </el-main>
      <Footer v-if="globalStore.themeConfig.footer"></Footer>
    </el-container>
  </el-container>
</template>

<script setup lang="ts">
import { reactive, ref, nextTick, watch } from 'vue';
import { Expand, Fold } from '@element-plus/icons-vue';
import Topbar from '../components/topBar/index.vue';
import Tabs from '../components/tabs/index.vue';
import Footer from '../components/footer/index.vue';
import { menuStore } from '@/stores/module/menu';
import { GlobalStore } from '@/stores';
import { useRoute, useRouter } from 'vue-router';
import { TabsStore } from '@/stores/module/tabs';
const route = useRoute();
const globalStore = GlobalStore();
const router = useRouter();
//TODO 解构获得类型
const useMenuStore = menuStore();
const tabStore = TabsStore();
//折叠菜单
const menuIsCollapse = ref(false);
const onMenuCollapse = () => {
  menuIsCollapse.value = !menuIsCollapse.value;
};
// 导航栏选中样式
let activeShow = ref();

let nextMenu = reactive<Array<any>>([]);

const menu = reactive<any>([]);
//   ([
//   {
//     path: '/',
//     meta: {
//       icon: 'icon-biaodanzujian-biaoge',
//       title: '组件',
//     },
//     children: [
//       {
//         path: '/component/tableSelect',
//         name: 'component-tableSelect',
//         meta: { title: '表格选择器' },
//       },
//       // {
//       //   path: '/component/remoteSelect',
//       //   meta: { title: '远程选择器' },
//       // },
//       // {
//       //   path: '/component/querySelect',
//       //   name: 'component-querySelect',
//       //   meta: { title: '查询选择器' },
//       // },
//       {
//         path: '/component/template',
//         name: 'component-template',
//         meta: { title: '表格模板' },
//       },
//       {
//         path: '/component/badge',
//         name: 'component-badge',
//         meta: { title: '状态徽标' },
//       },
//       {
//         path: '/component/proDrawer',
//         name: 'component-proDrawer',
//         meta: { title: '抽屉弹出框' },
//       },
//       {
//         path: '/component/fileSelect',
//         meta: { title: '资源选择器' },
//       },
//       {
//         path: '/component/map',
//         meta: { title: '位置选择器' },
//       },
//       {
//         path: '/component/proTable',
//         meta: { title: '表格' },
//       },
//       {
//         path: '/component/icon',
//         meta: { title: 'icones' },
//       },
//       {
//         path: '/component/WindiCss',
//         meta: { title: 'WindiCss' },
//       },
//     ],
//   },
// ]);

const title = ref('');

// 子导航栏点击事件
const addDread = (value: any, index?: number) => {
  // 是否有子项 手动格式化数组对象
  if (value[0].children) {
    title.value = value[0].children[index].name;
    useMenuStore.setBreadList([menu[useMenuStore.menuIndex].name, value[0].name, value[0].children[index].name]);
  } else {
    title.value = value[value.length - 1].name;
    useMenuStore.setBreadList([menu[useMenuStore.menuIndex].name, value[0].name]);
  }
};
// 父导航栏点击事件 初始化和父导航栏点击公用
const showMenu = (index: number, item?: any) => {
  activeShow.value = index;
  nextMenu.length = 0;
  // 是否有子项
  if (menu[index].children) {
    nextMenu.push(...menu[index].children);
    useMenuStore.setMenuIndex(index);
  } else {
    router.push({ path: menu[index].path });
  }
  // item
  if (item) {
    useMenuStore.setBreadList([menu[useMenuStore.menuIndex].name, menu[useMenuStore.menuIndex].children[0].name]);
    if (item.children[0].children) {
      title.value = item.children[0].children[0].name;
      router.push({ path: item.children[0].children[0].path });
    } else {
      router.push({ path: item.children[0].path });
      title.value = item.children[0].name;
    }
  }
};

nextTick(() => {
  menu.unshift(...useMenuStore.menuList);
  //初始化状态
  showMenu(useMenuStore.menuIndex);
});

// 监听路由的变化（防止浏览器后退/前进不变化 tabsMenuValue）
watch(
  () => route.path,
  (value) => {
    setTimeout(() => {
      let params = {
        title: title.value,
        breadList: [],
        path: route.path,
        close: true,
      };
      params.breadList.push(...useMenuStore.breadList);
      tabStore.addTabs(params);
    }, 100);
    tabStore.tabsMenuList.forEach((item) => {
      if (item.path === value) {
        useMenuStore.setBreadList(item.breadList);
      }
    });
  },
  {
    immediate: true,
  },
);

// 刷新当前页面
const isRouterRefresh = ref(true);
const refreshCurrentPage = () => {
  isRouterRefresh.value = false;
  nextTick(function () {
    isRouterRefresh.value = true;
  });
};
provide('refresh', refreshCurrentPage);
</script>

<style lang="scss">
.el-menu {
  border: 0px;
}
.icon {
  width: 10px;
  height: 1px;
  //vertical-align: -0.15em;
  ////fill: currentColor;
  //overflow: hidden;
}
.slide-fade-enter-active {
  animation: enter 0.9s;
}

.slide-fade-leave-active {
  animation: leave 0.4s;
}

@keyframes enter {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  50% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes leave {
  50% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(100px);
    opacity: 0;
  }
}
</style>
