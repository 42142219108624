<!--
横向布局  无面包屑
-->
<template xmlns:icons-svg--typcn="">
  <el-row>
    <el-col :span="20" style="background-color: #222b45; color: white">
      <el-menu
        :unique-opened="true"
        mode="horizontal"
        router
        @select="showMenu"
        text-color="#fff"
        :default-active="route.path"
        background-color="#222b45"
        :collapse-transition="false"
      >
        <div class="ml-3 mr-3">
          <div class="admin-side-split-top">
            <router-link to="/">
              <img class="logo" title="logo" src="../../assets/logo.png" />
            </router-link>
          </div>
        </div>
        <template v-for="(item, index) in menu" :key="item.id">
          <!--   有子项   -->
          <el-sub-menu :index="index" v-if="item.children">
            <template #title>
              <icons-font style="width: 25px; height: 18px" :name="item.meta.icon" type="svg"></icons-font>
              <span>{{ item.meta.title }}</span>
            </template>
            <template v-for="e in item.children" :key="e.id">
              <!--   二层有子项   -->
              <el-sub-menu :index="e.path" v-if="e.children">
                <template #title>
                  <icons-font style="width: 25px; height: 18px" :name="e.children[0].meta.icon" type="svg"></icons-font>
                  <span>{{ e.children[0].name }}</span>
                </template>
                <template v-for="a in e.children" :key="e.id">
                  <!--   三层无子项   -->
                  <el-menu-item :index="a.path" @click="showMenu(a)">
                    <template #title>
                      <icons-font style="width: 25px; height: 18px" :name="a.meta.icon" type="svg"></icons-font>
                      <span>{{ a.meta.title }}</span>
                    </template>
                  </el-menu-item>
                </template>
              </el-sub-menu>
              <!--   二层无子项   -->
              <el-menu-item :index="e.path" v-else @click="showMenu(e)">
                <template #title>
                  <icons-font style="width: 25px; height: 18px" :name="e.meta.icon" type="svg"></icons-font>
                  <span>{{ e.meta.title }}</span>
                </template>
              </el-menu-item>
            </template>
          </el-sub-menu>
          <!--   无子项   -->
          <el-menu-item :index="index" v-else @click="showMenu(item)">
            <template #title>
              <icons-font style="width: 25px; height: 18px" :name="item.meta.icon" type="svg"></icons-font>
              <span>{{ item.meta.title }}</span>
            </template>
          </el-menu-item>
        </template>
      </el-menu>
    </el-col>
    <el-col :span="4">
      <div class="flex h-full user dark">
        <slot></slot>
        <LockScreen></LockScreen>
        <Size></Size>
        <Setting></Setting>
        <Search></Search>
        <fullscreen></fullscreen>
        <Task></Task>
        <Message></Message>
        <Personal></Personal>
      </div>
    </el-col>
  </el-row>

  <el-container class="app-wrapper">
    <el-container class="admin-body">
      <Tabs v-if="globalStore.themeConfig.tabs"></Tabs>
      <el-main class="admin-main" id="admin-main">
        <RouterView v-slot="{ Component, route }">
          <Transition name="slide-fade">
            <keep-alive>
              <component :is="Component" :key="route.fullPath" />
            </keep-alive>
          </Transition>
        </RouterView>
      </el-main>
      <Footer></Footer>
    </el-container>
  </el-container>
</template>

<script setup lang="ts">
import { reactive, ref, nextTick, watch } from 'vue';
import Tabs from '../components/tabs/index.vue';
import Footer from '../components/footer/index.vue';
import { menuStore } from '@/stores/module/menu';
import { GlobalStore } from '@/stores';
import { useRoute, useRouter } from 'vue-router';
import { TabsStore } from '@/stores/module/tabs';
import Search from '../components/topBar/search.vue';
import Message from '../components/topBar/message.vue';
import Personal from '../components/topBar/personal.vue';
import Setting from '../components/topBar/setting.vue';
import Task from '../components/task/index.vue';
import Size from '../components/topBar/size.vue';
import LockScreen from '../components/topBar/lockscreen.vue';
import fullscreen from '../components/topBar/fullscreen.vue';
const route = useRoute();
const globalStore = GlobalStore();
const router = useRouter();
//TODO 解构获得类型
const useMenuStore = menuStore();
const tabStore = TabsStore();

const menu = reactive<any>([
  {
    path: '/',
    meta: {
      icon: 'icon-biaodanzujian-biaoge',
      title: '组件',
    },
    children: [
      {
        path: '/component/tableSelect',
        name: 'component-tableSelect',
        meta: { title: '表格选择器' },
      },
      {
        path: '/component/template',
        name: 'component-template',
        meta: { title: '表格模板' },
      },
      {
        path: '/component/badge',
        name: 'component-badge',
        meta: { title: '状态徽标' },
      },
      {
        path: '/component/proDrawer',
        name: 'component-proDrawer',
        meta: { title: '抽屉弹出框' },
      },
      {
        path: '/component/fileSelect',
        meta: { title: '资源选择器' },
      },
      {
        path: '/component/map',
        meta: { title: '位置选择器' },
      },
      {
        path: '/component/proTable',
        meta: { title: '表格' },
      },
      {
        path: '/component/icon',
        meta: { title: 'icones' },
      },
      {
        path: '/component/WindiCss',
        meta: { title: 'WindiCss' },
      },
    ],
  },
]);

const title = ref('');
// 导航栏点击事件
const showMenu = (item: any) => {
  title.value = item.name;
};

nextTick(() => {
  menu.unshift(...useMenuStore.menuList);
});

// 监听路由的变化（防止浏览器后退/前进不变化 tabsMenuValue）
watch(
  () => route.path,
  () => {
    setTimeout(() => {
      let params = {
        title: title.value,
        path: route.path,
        close: true,
      };
      tabStore.addTabs(params);
    }, 100);
  },
  {
    immediate: true,
  },
);

// 刷新当前页面
const isRouterRefresh = ref(true);
const refreshCurrentPage = () => {
  isRouterRefresh.value = false;
  nextTick(function () {
    isRouterRefresh.value = true;
  });
};
provide('refresh', refreshCurrentPage);
</script>

<style lang="scss">
.user {
  background-color: #222b45;
  color: #fff;
}
.el-menu {
  border: 0px;
}
.icon {
  width: 10px;
  height: 1px;
  //vertical-align: -0.15em;
  ////fill: currentColor;
  //overflow: hidden;
}
.slide-fade-enter-active {
  animation: enter 0.9s;
}

.slide-fade-leave-active {
  animation: leave 0.4s;
}

@keyframes enter {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  50% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes leave {
  50% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(100px);
    opacity: 0;
  }
}
</style>
