

import __layout_0 from '/Applications/XAMPP/xamppfiles/htdocs/Tian/tian-admin/src/layouts/default.vue'
export const layouts = {
'blank': () => import('/Applications/XAMPP/xamppfiles/htdocs/Tian/tian-admin/src/layouts/blank.vue'),
'default': __layout_0,
'public': () => import('/Applications/XAMPP/xamppfiles/htdocs/Tian/tian-admin/src/layouts/public.vue'),
'work': () => import('/Applications/XAMPP/xamppfiles/htdocs/Tian/tian-admin/src/layouts/work.vue'),
'LayoutClassic/index': () => import('/Applications/XAMPP/xamppfiles/htdocs/Tian/tian-admin/src/layouts/LayoutClassic/index.vue'),
'LayoutColumns/index': () => import('/Applications/XAMPP/xamppfiles/htdocs/Tian/tian-admin/src/layouts/LayoutColumns/index.vue'),
'LayoutTransverse/index': () => import('/Applications/XAMPP/xamppfiles/htdocs/Tian/tian-admin/src/layouts/LayoutTransverse/index.vue'),
'LayoutVertical/index': () => import('/Applications/XAMPP/xamppfiles/htdocs/Tian/tian-admin/src/layouts/LayoutVertical/index.vue'),
'lockScreen/index': () => import('/Applications/XAMPP/xamppfiles/htdocs/Tian/tian-admin/src/layouts/lockScreen/index.vue'),
'components/tabs/index': () => import('/Applications/XAMPP/xamppfiles/htdocs/Tian/tian-admin/src/layouts/components/tabs/index.vue'),
'components/footer/index': () => import('/Applications/XAMPP/xamppfiles/htdocs/Tian/tian-admin/src/layouts/components/footer/index.vue'),
'components/task/index': () => import('/Applications/XAMPP/xamppfiles/htdocs/Tian/tian-admin/src/layouts/components/task/index.vue'),
'components/task/tasks': () => import('/Applications/XAMPP/xamppfiles/htdocs/Tian/tian-admin/src/layouts/components/task/tasks.vue'),
'components/themeDrawer/index': () => import('/Applications/XAMPP/xamppfiles/htdocs/Tian/tian-admin/src/layouts/components/themeDrawer/index.vue'),
'components/topBar/fullscreen': () => import('/Applications/XAMPP/xamppfiles/htdocs/Tian/tian-admin/src/layouts/components/topBar/fullscreen.vue'),
'components/topBar/index': () => import('/Applications/XAMPP/xamppfiles/htdocs/Tian/tian-admin/src/layouts/components/topBar/index.vue'),
'components/topBar/lockscreen': () => import('/Applications/XAMPP/xamppfiles/htdocs/Tian/tian-admin/src/layouts/components/topBar/lockscreen.vue'),
'components/topBar/message': () => import('/Applications/XAMPP/xamppfiles/htdocs/Tian/tian-admin/src/layouts/components/topBar/message.vue'),
'components/topBar/personal': () => import('/Applications/XAMPP/xamppfiles/htdocs/Tian/tian-admin/src/layouts/components/topBar/personal.vue'),
'components/topBar/search': () => import('/Applications/XAMPP/xamppfiles/htdocs/Tian/tian-admin/src/layouts/components/topBar/search.vue'),
'components/topBar/setting': () => import('/Applications/XAMPP/xamppfiles/htdocs/Tian/tian-admin/src/layouts/components/topBar/setting.vue'),
'components/topBar/size': () => import('/Applications/XAMPP/xamppfiles/htdocs/Tian/tian-admin/src/layouts/components/topBar/size.vue'),
'components/tabs/components/MoreButton': () => import('/Applications/XAMPP/xamppfiles/htdocs/Tian/tian-admin/src/layouts/components/tabs/components/MoreButton.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      component: layouts[route.meta?.layout || 'default'],
      children: [ {...route, path: ''} ],
    }
  })
}
