<template>
  <div id="drawer">
    <el-drawer v-bind="$attrs" :show-close="false" :before-close="customColose">
      <!--关闭按钮方案1 使用header头中close-->
      <!-- <template #header="{ close, titleId, titleClass }">
        <div :id="titleId" class="drawer-close" @click="close">关闭</div>
      </template> -->
      <!--关闭按钮方案2 在body 标签中使用自定义方法关闭-->
      <div class="drawer-close" @click="customColose">关闭</div>
      <slot name="body"></slot>
      <!-- 遍历实现插槽透传 -->
      <template v-for="(item, key, index) in $slots" :key="index" v-slot:[key]>
        <slot :name="key"></slot>
      </template>
    </el-drawer>
  </div>
</template>
<script lang="ts" setup>
const emit = defineEmits(['update:modelValue']);
const customColose = () => {
  emit('update:modelValue', false);
};
</script>
<style scoped lang="scss">
#drawer {
  :deep(.el-drawer) {
    overflow: visible !important;
  }
}
.drawer-close {
  position: absolute;
  width: 38px;
  height: 120px;
  padding: 30px 6px;
  line-height: 30px;
  text-align: center;
  left: -38px;
  right: 0;
  top: 20%;
  background-color: #ff5722;
  color: #fff;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
  border: 2px solid #fff;
  border-right: none;
  font-weight: 600;
  font-size: 14px;
}
.drawer-close:hover {
  opacity: 0.9;
}
</style>
