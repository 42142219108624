/** 应用基础配置 */
export default {
  /** 语言缓存KEY名 */
  localeStorageKey: 'app-locale',
  /** 阿里图标库 */
  iconFont: {
    /** font-class 字体格式图标 */
    linkUrl: ['/assets/iconfont/iconfont.css', '//at.alicdn.com/t/font_180975_ue66sq60vyd.css'],
    /** Symbol Svg格式图标 */
    //scriptUrl: ['/assets/iconfont/iconfont.js', '//at.alicdn.com/t/font_180975_ue66sq60vyd.js'],
    scriptUrl: ['//at.alicdn.com/t/c/font_2304029_k8r3hgvq91.js'],
  },
};
// * 首页地址（默认）
export const HOME_URL: string = '/';

// * 默认主题颜色
export const DEFAULT_PRIMARY: string = '#009688';

// * Tabs（黑名单地址，不需要添加到 tabs 的路由地址）  公共地址
export const TABS_BLACK_LIST: string[] = ['/public/403', '/public/404', '/public/500', '/public/regster', '/public/login', '/public/restPaw'];
