<template>
  <el-dropdown class="h-full w-full panel-item" trigger="click" @command="handleUser">
    <div class="flex h-full items-center">
      <el-avatar :size="28" :src="avatarUrl" />
      <label class="ml-1 mr-1" style="cursor: pointer">{{ userInfo.nick_name }}</label>
      <el-icon class="el-icon--right"><arrow-down /></el-icon>
    </div>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item command="uc">个人中心</el-dropdown-item>
        <el-dropdown-item command="clearCache">清除缓存</el-dropdown-item>
        <el-dropdown-item divided command="outLogin">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script setup lang="ts">
//个人中心
import { router } from '@/plugins/components/vue3-router';
import { Logout } from '@/api/module/common';
import { ElMessage } from 'element-plus';
import { computed } from 'vue';
import { ArrowDown } from '@element-plus/icons-vue';
import { TabsStore } from '@/stores/module/tabs';
import { menuStore } from '@/stores/module/menu';
const userStore = useUserStore();
const menu = menuStore();

const { userInfo } = userStore;

const tabStore = TabsStore();

// 头像显示
const avatarUrl = computed(() => {
  if (userInfo.avatar == '') {
    return 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png';
  } else {
    return userInfo.avatar;
  }
});

const handleUser = (val: string) => {
  //个人中心
  if (val === 'uc') {
    router.push({
      path: '/system/user/profile',
    });
  }
  //更新缓存
  if (val === 'clearCache') {
    console.log(val);
  }
  //退出登录
  if (val === 'outLogin') {
    console.log(val);
    Logout().run({
      manual: false,
      onSuccess: () => {
        userStore.$reset();
        menu.$reset();
        // menu.clear();
        tabStore.closeMultipleTab();
        router.push({
          path: '/public/login',
        });
        ElMessage.success('退出成功~');
      },
    });
  }
};
</script>

<style scoped lang="scss"></style>
