<template>
  <div class="panel-item" @click="tasks">
    <el-tooltip effect="dark" content="任务中心" placement="bottom">
      <el-icon>
        <sort />
      </el-icon>
    </el-tooltip>
  </div>

  <el-drawer v-model="tasksVisible" :size="450" title="任务中心" custom-class="drawerBG" destroy-on-close>
    <Tasks></Tasks>
  </el-drawer>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import Tasks from './tasks.vue';

const tasksVisible = ref(false);
//打开任务中心
const tasks = () => {
  tasksVisible.value = true;
};
</script>

<style scoped lang="scss"></style>
