import api, { type IDataType, METHOD } from '@/api';

interface getListParams {
  category_id?: string;
  title?: string;
  type?: number;
  page?: number;
  pageSize?: number;
  date_time?: string;
  status?: number;
}

// 返回参数类型声明
interface ITestResult {
  list: Array<any>;
}

// 通知公告 获取列表
export const getList = (data?: getListParams) => {
  return api.use<IDataType<ITestResult>, [getListParams]>({
    url: '/system/notice/getlist',
    method: METHOD.POST,
    data,
  });
};

interface AddParams {
  notice_id?: string;
  title: string;
  category_id: string;
  type: number;
  status: number;
  sorting: number;
  content: string;
  remark: string;
}

// 通知公告 添加
export const add = (data?: AddParams) => {
  return api.use<IDataType<ITestResult>, [AddParams]>({
    url: '/system/notice/add',
    method: METHOD.POST,
    data,
  });
};

interface DeleteParams {
  notice_id: number;
}

// 通知公告 删除
export const noticeDelete = (data?: DeleteParams) => {
  return api.use<IDataType<ITestResult>, [DeleteParams]>({
    url: '/system/notice/delete',
    method: METHOD.POST,
    data,
  });
};

// 通知公告 获取一条数据
export const getOne = (data?: DeleteParams) => {
  return api.use<IDataType<ITestResult>, [DeleteParams]>({
    url: '/system/notice/getone',
    method: METHOD.POST,
    data,
  });
};

// 通知公告 修改
export const update = (data?: AddParams) => {
  return api.use<IDataType<ITestResult>, [AddParams]>({
    url: '/system/notice/update',
    method: METHOD.POST,
    data,
  });
};

// 通知公告 获取分类
export const noticeCategory = (data?: getListParams) => {
  return api.use<IDataType<ITestResult>, [getListParams]>({
    url: '/system/noticecategory/getlist',
    method: METHOD.POST,
    data,
  });
};
