{
  "language": "英语",
  "global-hello": "Hello, World-China-Global!",
  "hello": "Hello World，I am China",
  "tabs": {
    "more": "More",
    "refresh": "Refresh",
    "closeCurrent": "Close current",
    "closeOther": "Close other",
    "closeAll": "Close All"
  }
}
